import React, { Component } from 'react';

import Logo from '../assets/img/nestle-russia-logo.png';

import { INTRA } from '../constants/vacancies';

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            search: '',
            openMobileSearch: false
        }
    }

    onSearch = e => {
        e.preventDefault();
        this.searchLink.click();
    }

    openMobileSearch = () => this.setState({ openMobileSearch: !this.state.openMobileSearch });

    clearSearch = () => this.setState({ search: '' });

    render() {
        return INTRA ? null : (
            <div className="header">
                <div className="container">
                    <div className="row-header">
                        <a className="logo" href="/">
                            <img src={Logo} alt=""/>
                        </a>
                        <div className={this.state.openMobileSearch ? 'open-search' : undefined}>
                            <form className="search" onSubmit={this.onSearch}>
                                <input type="text" placeholder="Поиск" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} />
                                { !!this.state.search &&
                                    <button className="btn-search-clear" type="button" onClick={this.clearSearch}>
                                        <img src={require('../assets/img/cross.svg')} alt='close' />
                                    </button>
                                }
                                <button className="btn-search" type="submit" placeholder="Search"></button>
                            </form>
                            <button className="btn-search btn-search-mobile" onClick={this.openMobileSearch} />
                            {/* eslint-disable-next-line */}
                            <a ref={node => this.searchLink = node} href={`https://www.nestle.ru/search?keyword=${this.state.search}&filter_result=Все&sort_bef_combine=search_api_relevance+DESC`}></a>
                        </div>
                    </div>
                </div>
                <div className="menu-wrap">
                    <div className="container">
                        <div className={this.state.active ? 'open-menu' : undefined}>
                            <div className="nav-wrap">
                                <ul className="nav">
                                    <li className="home">
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/aboutus">О компании</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/stories">Наши истории</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/brands">Наши бренды</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/sharedvalues">Создание общих ценностей</a>
                                    </li>
                                </ul>
                                <ul className="nav">
                                    <li>
                                        <a href="https://www.nestle.ru/media">Медиа-центр</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/jobs">Карьера</a>
                                    </li>
                                </ul>
                            </div>
                            <button className="hamburger-menu" onClick={ () => this.setState({active: !this.state.active}) }><span /></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
