import React, { Component, Fragment } from 'react';
import ReactPaginate from 'react-paginate';
import qs from 'qs';
import { find, omit, path, pathEq, pathOr } from 'ramda';
import Modal from 'react-modal';
import { Query } from 'react-apollo';
import { ToastContainer } from 'react-toastify';

import { getDomain, getVacancies } from '../queries';
import Cover from './Cover';
import Loader from './Loader';
import { DOMAIN, LINES_PER_PAGE } from '../constants/vacancies';
import VacancyModalContent from './VacancyModalContent';

Modal.setAppElement('#root');

export default class Vacancies extends Component {
    state = {
        modal: null
    };

    onPageChange = ({ selected }) => {
        const searchPath = qs.stringify({
            ...this.getSearchParams(),
            page: selected + 1
        }, { addQueryPrefix: true });

        this.props.history.replace(`/${searchPath}`);
    }

    getSearchParams = () => {
        return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    }

    closeModal = () => this.setState({ modal: null });

    renderVacancies = ({ data, loading, error }, domain) => {
        const count = pathOr(0, ['vacancies', '_meta', 'count'], data);
        const pages = Math.ceil(count / LINES_PER_PAGE);
        const items = pathOr([], ['vacancies', 'items'], data)
        const searchParams = this.getSearchParams();

        return <Fragment>
            <Cover filters={searchParams} domain={domain.id} />
            { loading && !data ?
                <div style={{ paddingBottom: 65 }}>
                    <Loader />
                </div> :
                count ? <div className="view-search">
                    <div className="container">
                        <div style={{ marginBottom: 20, fontSize: 18 }}>
                            Список вакансий: { (((searchParams.page || 1) - 1) * LINES_PER_PAGE) + 1 }-{ (searchParams.page || 1) * LINES_PER_PAGE > count ? count : (searchParams.page || 1) * LINES_PER_PAGE } из { count }
                        </div>
                        <div className='vacancies-table'>
                            <table className="list">
                                <thead>
                                <tr>
                                    <th className="title">Название вакансии</th>
                                    <th className="company">Город</th>
                                    <th className="function">Функциональное направление</th>
                                </tr>
                                </thead>
                                <tbody>
                                { items.map(item =>
                                    <tr key={item.id}>
                                        <td className="title">
                                            <span onClick={() => this.setState({ modal: item })}>{ item.title }</span>
                                        </td>
                                        <td className="title">
                                            <span onClick={() => this.setState({ modal: item })}>{ (item.cities || []).reduce((res, cur, index) => res + (index ? `, ${cur.name}` : cur.name), '') }</span>
                                        </td>
                                        <td className="title">
                                            <span onClick={() => this.setState({ modal: item })}>{ path(['name'], find(pathEq(['classifier', 'name'], 'Функциональное направление'), item.classifiers || [])) }</span>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            { !!loading &&
                                <div className='vacancies-table-loader'>
                                    <Loader />
                                </div>
                            }
                        </div>
                        { pages > 1 &&
                            <ReactPaginate
                                containerClassName="paginate"
                                activeClassName="active"
                                pageCount={pages}
                                pageRangeDisplayed={LINES_PER_PAGE}
                                forcePage={(searchParams.page ? Number(searchParams.page) : 1) - 1}
                                onPageChange={this.onPageChange}
                                previousLabel='Назад'
                                nextLabel='Вперед' />
                        }
                    </div>
                    <Modal
                        isOpen={!!this.state.modal}
                        onRequestClose={this.closeModal}
                        style={{
                            content : {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                padding: 0,
                            }
                        }}>
                        <button className='modal-close' onClick={this.closeModal}>
                            <img alt='close' src={require('../assets/img/cross.svg')} />
                        </button>
                        { !!this.state.modal &&
                            <VacancyModalContent vacancy={this.state.modal} domain={domain} close={this.closeModal} />
                        }
                    </Modal>
                    <ToastContainer />
                </div> : <div className="view-search">
                    <div className="container empty">
                        { error ? 'Ошибка сервера - не удалось загрузить список вакансий' : 'Ничего не найдено' }
                    </div>
                </div>
            }
        </Fragment>;
    }

    render() {
        const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        return <Query query={getDomain} variables={{ name: DOMAIN }}>
            { ({ data }) =>
                path(['domain'], data) ?
                    <Query query={getVacancies} variables={{
                        filter: {
                            domainName: DOMAIN,
                            ...omit(['page', 'city', 'classifier'], params),
                            cities: params.city ? [params.city] : undefined
                        },
                        sorting: {
                            field: 'datePublishedStart',
                            order: -1
                        },
                        pagination: {
                            offset: ((params.page || 1) - 1) * LINES_PER_PAGE,
                            limit: LINES_PER_PAGE
                        }
                    }}>
                        { vacanciesData => this.renderVacancies(vacanciesData, data.domain) }
                    </Query> : <div />
            }
        </Query>;
    }
}
