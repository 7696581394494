import React, { Component } from 'react';

import WorldMap from '../assets/img/worldmap.png';
import NestLogo from '../assets/img/nest-logo.png';

import { INTRA } from '../constants/vacancies';

export default class Footer extends Component {
    render() {
        return INTRA ? null : (
            <div className="footer-wrapper">
                <div className="footer-mini">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <h3>
                                    <a href="https://www.nestle.com/" rel="noopener">Nestlé Global</a>
                                </h3>
                                <a className="btn btn-white" href="https://www.nestle.com/" target="_self">Перейти</a>
                            </div>
                            <div className="col-md-3">
                                <h3>
                                    <a href="https://www.nestle.ru/aboutus/principle/report-your-concerns">Принципы Нестле – сообщите о возможном несоответствии</a>
                                </h3>
                                <a className="btn btn-transparent" href="https://www.nestle.ru/aboutus/principle/report-your-concerns" target="_self">Сообщить</a>
                            </div>
                            <div className="col-md-3">
                                <a href="https://www.nestle.ru/info">
                                    <img alt="map" title="map" className="img-responsive" src={WorldMap} />
                                </a>
                            </div>
                            <div className="col-md-3">
                                <h3>
                                    <a href="https://www.nestle.ru/info" rel="noopener">Контакты</a>
                                </h3>
                                <a className="btn btn-transparent" href="https://www.nestle.ru/info" target="_self">Перейти</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <h2 className="block-title">Nestlé</h2>
                                <p>Раскрывая пользу продуктов, мы повышаем качество жизни каждого - сегодня и для будущих поколений</p>
                                <img src={NestLogo} alt="Nestlé" title="Nestlé" className="footer-logo" />
                            </div>
                            <div className="col-md-3 col-nav">
                                <h2>О компании</h2>
                                <ul className="menu">
                                    <li>
                                        <a href="http://nestle.com">«Нестле» в мире </a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/aboutus/russia-eurasia-nestle">«Нестле» в России и Евразии</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/aboutus/russuiannestle/fabriki_i_officy">Фабрики и филиалы</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/brands">Наши бренды</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/sharedvalues">Создание общих ценностей</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/aboutus/policies-and-documents">Политики и документы </a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/aboutus/history">История компании</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-nav">
                                <h2>Полезные ссылки</h2>
                                <ul className="menu">
                                    <li>
                                        <a href="https://www.nestle.ru/info">Контакты</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/info">Вопросы о нашей продукции</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/ask-nestle">Часто задаваемые вопросы</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/aboutus/principle/report-your-concerns">Принципы Нестле - сообщите о
                                            возможном несоответствии</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-nav">
                                <h2>Медиа-центр</h2>
                                <ul className="menu">
                                    <li>
                                        <a href="https://www.nestle.ru/media/newscomp">Новости компании</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/media/media_contacts">Контакты для СМИ</a>
                                    </li>
                                    <li>
                                        <a href="https://www.nestle.ru/media/pressreleases">Пресс-релизы</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-below">
                    <div className="container">
                        <ul className="menu sub-footer-menu">
                            <li>
                                <a href="https://www.nestle.ru/info/2020/nestl%C3%A9" rel="noopener" title="Авторские права">Авторские права</a>
                            </li>
                            <li>
                                <a href="https://www.nestle.ru/info/%D0%9A%D0%B0%D1%80%D1%82%D0%B0%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0"
                                   rel="noopener" title="Карта сайта">Карта
                                    сайта</a>
                            </li>
                            <li>
                                <a href="https://www.nestle.ru/info/%D0%9F%D0%BE%D0%BC%D0%BE%D1%89%D1%8C" rel="noopener" title="Помощь">Помощь</a>
                            </li>
                            <li>
                                <a href="https://www.nestle.ru/sites/g/files/pydnoa571/files/2020-02/Политика в отношении обработки ПДн.pdf"
                                   rel="noopener"
                                   title="Конфиденциальность и условия использования">Конфиденциальность</a>
                            </li>
                            <li>
                                <a href="https://www.nestle.ru/info/%D0%9E%20%D1%81%D0%B0%D0%B9%D1%82%D0%B5" rel="noopener" title="О сайте">О сайте</a>
                            </li>
                            <li>
                                <a href="https://www.nestle.ru/info" rel="noopener" title="Контакты">Контакты</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
};
