import gql from 'graphql-tag';

export const getVacancies = gql`
    query vacancies($filter: VacancyFilterInput, $pagination: PaginationInput, $sorting: SortInput) {
        vacancies(filter: $filter, pagination: $pagination, sorting: $sorting) {
            items {
                id,
                title,
                description,
                code,
                datePublishedStart,
                specialization,
                experienceRequirements,
                address,
                employeeType {
                    value
                },
                cities {
                    name
                },
                classifiers {
                    id,
                    name,
                    classifier {
                        id,
                        name
                    }
                },
                company {
                    id,
                    name
                },
                domains {
                    title,
                    name
                },
                category {
                    name
                },
                salaryViewType,
                minSalary,
                maxSalary,
                salaryPeriod,
                salaryType {
                    name,
                    value
                },
                salaryCurrency {
                    code
                },
                employeeType {
                    name
                },
                chartOfWork {
                    name
                },
                pda
            },
            _meta {
                count
            }
        }
    }
`;

export const getDomain = gql`
    query domain($name: String) {
        domain(name: $name) {
            id,
            name,
            company {
                id,
                name,
                companySettings {
                    allowFileExtensions,
                    maxFileUploadSize,
                    captcha {
                        domains
                    }
                },
                pdaSettings {
                    text,
                    labelStart,
                    labelEnd,
                    labelLink,
                    linkType,
                    link,
                    translations,
                    data,
                    acceptButtonLabel,
                    acceptCheckbox {
                        required,
                        active,
                        label
                    },
                    purposeCheckbox {
                        required,
                        active,
                        label
                    },
                    mailingCheckbox {
                        required,
                        active,
                        label
                    },
                    transmissionCheckbox {
                        required,
                        active,
                        label
                    },
                    useConstructor
                }
            },
            vacancyFormPreset {
                questions {
                    type,
                    field,
                    question,
                    required,
                    label,
                    settings,
                    translations
                },
                options {
                    captchaType,
                    captchaToken,
                    captchaData
                }
            },
            domainFormPreset {
                questions {
                    type,
                    field,
                    question,
                    required,
                    label,
                    settings,
                    translations
                },
                options {
                    captchaType,
                    captchaToken,
                    captchaData
                }
            }
        }
    }
`;

export const getVacancyFilters = gql`
    query vacancyFilters($filter: VacancyFilterInput) {
        vacancyFilters(filter: $filter) {
            cities {
                city {
                    id,
                    name
                }
            },
            classifiers {
                classifierOption {
                    id,
                    name,
                    classifier {
                        id,
                        name
                    }
                }
            }
        }
    }
`;

export const createApplicant = gql`
    mutation createApplicant($vacancy: String!, $form: JSON, $domain: String) {
        createApplicant(vacancy: $vacancy, form: $form, domain: $domain) {
            id
        }
    }
`;
