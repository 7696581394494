import React from 'react';

const Loader = () => {
    return (
        <div className='loader-wrapper'>
            <div className='loader-item' />
        </div>
    );
};

export default Loader;
