import 'react-app-polyfill/ie11';
import 'string.prototype.startswith';
import 'ie-array-find-polyfill';

import React from 'react';
import { hydrate, render } from 'react-dom';

import App from './components/App';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}
