import React, { Component, Fragment } from 'react';
import { find, path, pathEq, pathOr } from 'ramda';
import { toast } from 'react-toastify';
import Form from '@experium/findy-form';
import { Mutation } from 'react-apollo';

import '@experium/findy-form/lib/styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

import { createApplicant } from '../queries';
import { DOMAIN, INTRA } from '../constants/vacancies';

const isSalaryFixed = viewType => viewType === 'fixed';
const intervalTemplate = (from, to, preFrom = '', preTo = '', empty = '', delimiter = '-') => {
    let result = '';
    if (from && to) {
        result += delimiter ? `${from} ${delimiter} ${to}` : `${preFrom} ${from} ${preTo} ${to}`;
    } else {
        result += from ? `${preFrom} ${from} ` : '';
        result += to ? `${preTo} ${to} ` : '';
    }
    return result || empty;
};
const SALARY_FORMAT = (value) => value ? String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';

export default class VacancyModalContent extends Component {
    state = {
        form: false
    };

    setForm = () => this.setState({ form: true });

    getSalary = vacancy => {
        const salaryPeriods = {
            hour: 'за час',
            shift: 'за смену',
            month: 'за месяц'
        };
        const period = salaryPeriods[vacancy.salaryPeriod];
        const currency = path(['salaryCurrency', 'code'], vacancy);
        const type = path(['salaryType', 'name'], vacancy);
        const typeTranslate = pathOr(type, ['salaryType', 'translations', 'name', 'ru'], vacancy);
        const min = vacancy.minSalary;
        const max = vacancy.maxSalary;
        const viewType = vacancy.salaryViewType;

        return (min || max) && (
            <span dangerouslySetInnerHTML={{ __html: (`
                ${ min === max && min ?
                    min :
                    intervalTemplate(
                        SALARY_FORMAT(min), SALARY_FORMAT(max),
                        isSalaryFixed(viewType)? '' : 'от',
                        isSalaryFixed(viewType) ? '' : 'до', '-', ''
                    )
                } ${period || ''} ${currency || ''} ${typeTranslate ? `<br />(${typeTranslate})` : ''}
            `) }} />
        );
    }

    renderItem = (label, value) => {
        return value ? <Fragment>
            <h3>{ label }</h3>
            <div>{ value }</div>
        </Fragment> : null;
    }

    renderVacancy = () => {
        const { vacancy } = this.props;
        const cities = vacancy.cities && vacancy.cities.length ? vacancy.cities.reduce((res, cur, index) => res + (index ? `, ${cur.name}` : cur.name), '') : '';
        const classifier = path(['name'], find(pathEq(['classifier', 'name'], 'Функциональное направление'), vacancy.classifiers || []));
        const classifierExperience = path(['name'], find(pathEq(['classifier', 'name'], 'Требуемый опыт'), vacancy.classifiers || []));
        const classifierContact = path(['name'], find(pathEq(['classifier', 'name'], 'Контакты ответственного за вакансию'), vacancy.classifiers || []));

        return <Fragment>
            <div className='vacancy-content-modal-info-block'>
                <div>
                    <div className='vacancy-content-modal-info-block-title'>Заработная плата</div>
                    <div>{ this.getSalary(vacancy) || 'не указана' }</div>
                </div>
                <div>
                    <div className='vacancy-content-modal-info-block-title'>Город</div>
                    <div>{ cities || 'не указан' }</div>
                </div>
                <div>
                    <div className='vacancy-content-modal-info-block-title'>Требуемый опыт</div>
                    <div>{ classifierExperience || 'не указан' }</div>
                </div>
            </div>
            { vacancy.description &&
                <Fragment>
                    <h3>Описание вакансии</h3>
                    <div dangerouslySetInnerHTML={{ __html: vacancy.description }} />
                </Fragment>
            }
            { this.renderItem('Место работы', path(['name'], find(pathEq(['classifier', 'name'], 'Место работы'), vacancy.classifiers || []))) }
            { this.renderItem('Тип занятости', path(['employeeType', 'value'], vacancy)) }
            { this.renderItem('Страна', path(['name'], find(pathEq(['classifier', 'name'], 'Страна'), vacancy.classifiers || []))) }
            { this.renderItem('Функциональное направление', classifier) }
            { this.renderItem('Специализация', path(['name'], find(pathEq(['classifier', 'name'], 'Специализация'), vacancy.classifiers || []))) }
            { INTRA ? (
                <p className="b-vacancy-respond-message">
                    Если у Вас есть желание принять участие в конкурсе, пожалуйста, пришлите резюме с письмом-подтверждением согласия Вашего руководителя на электронный адрес {classifierContact} С процедурой подачи заявки на открытую внутреннюю вакансию Вы можете ознакомиться в разделе «Моя карьера и развитие» на me@Nestle.
                </p>
            ) : (
                <button className='apply-btn' onClick={this.setForm}>Откликнуться</button>
            )}
        </Fragment>;
    }

    onSubmit = ({ vacancy, ...form }, mutation) => {
        mutation({
            variables: {
                form: {
                    ...form,
                    _tool: `https://${DOMAIN}`,
                },
                vacancy: this.props.vacancy.id,
                domain: this.props.domain.id
            }
        });
    }

    renderForm = () => {
        const { vacancy, domain, close } = this.props;

        return <Mutation
            mutation={createApplicant}
            onCompleted={() => {
                toast.success('Отклик успешно отправлен');
                close();
            }}
            onError={() => toast.error('Не удалось отправить отклик')}
        >
            { (mutation, { loading, error }) => {
                const pdaCompany = path(['company', 'pdaSettings', 'text'], domain);
                const pda = path(['company', 'companySettings', 'pda'], domain) || pdaCompany;
                const allowFileExtensions = path(['company', 'companySettings', 'allowFileExtensions'], domain);
                const captcha = path(['company', 'companySettings', 'captcha', 'domains'], domain);

                return <Form
                    fields={pathOr([], ['vacancyFormPreset', 'questions'], domain)}
                    onSubmit={values => this.onSubmit(values, mutation)}
                    postFileUrl={`/api/landing/public/file/${vacancy.id}`}
                    getFileUrl={id => `/api/file/${id}`}
                    opd={pda}
                    allowFileExtensions={allowFileExtensions}
                    serverErrors={error}
                    submitting={loading}
                    apiUrl={'/'}
                    captcha={captcha}
                    captchaOptions={pathOr({}, ['vacancyFormPreset', 'options'], domain)}
                    company={path(['company', 'id'], vacancy)}
                    opdSettings={pathOr({}, ['company', 'pdaSettings'], domain)}
                />;
            }}
        </Mutation>;
    }

    render() {
        const { vacancy } = this.props;

        return <div className='vacancy-content-modal'>
            <h1>{ vacancy.title }</h1>
            { this.state.form && !INTRA ?
                this.renderForm() :
                this.renderVacancy()
            }
        </div>;
    }
}
