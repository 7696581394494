import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import { append, filter, includes, omit, path, pathEq, pathOr, prop, sortBy, without } from 'ramda';
import { withState } from 'recompose';
import { Query } from 'react-apollo';

import CoverImg from '../assets/img/job-search-main.jpg';

import { getVacancyFilters } from '../queries';
import { INTRA } from '../constants/vacancies';

class Cover extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: props.filters
        };
    }

    search = e => {
        e.preventDefault();

        const searchPath = qs.stringify(filter(value => !!value, {
            ...this.state.filters,
            page: 1
        }), { addQueryPrefix: true });

        this.props.history.replace(`/${searchPath}`);
    }

    onChange = (key, e) => {
        const { value } = e.target;

        this.setState(prev => {
            const filters = filter(value => !!value, {
                ...prev.filters,
                [key]: value || undefined
            });
            this.props.setFacetFilters(filters);

            return { filters };
        });
    }

    onChangeClassifier = (currentValue, e) => {
        const current = this.state.filters.classifiers || [];
        let classifier = without([currentValue], current);
        classifier = e.target.value ? append(e.target.value, classifier) : classifier;

        this.setState(prev => {
            const filters = filter(value => !!value, {
                ...prev.filters,
                classifiers: classifier.length ? classifier : null
            });
            this.props.setFacetFilters(filters);

            return { filters };
        });
    }

    renderFilter = ({ data }) => {
        const { filters } = this.state;
        const functions = filter(item =>
            pathEq(['classifierOption', 'classifier', 'name'], 'Функциональное направление', item), pathOr([], ['vacancyFilters', 'classifiers'], data)
        ).map(({ classifierOption }) => classifierOption);
        const countries = filter(
            item => pathEq(['classifierOption', 'classifier', 'name'], 'Страна', item), pathOr([], ['vacancyFilters', 'classifiers'], data)
        ).map(({ classifierOption }) => classifierOption);
        const classifier = filters.classifiers || [];
        const currentFunction = path([0, 'id'], filter(({ id }) => includes(id, classifier), functions));
        const currentCountry = path([0, 'id'], filter(({ id }) => includes(id, classifier), countries));

        return (
            <div>
                <div className="cover">
                    <img src={CoverImg} alt="Cover"/>
                </div>
                <div className="main-field">
                    <div className="container">
                        { INTRA ? null : <h1 className="slogan">Сотни рабочих мест</h1> }
                    </div>
                    <div className="filter-wrap">
                        <form onSubmit={this.search} className="filter-box">
                            { INTRA ? null : <h3>Найдите то, которое подходит именно вам</h3> }
                            <div className="filter">
                                <div className="form-item">
                                    <label className="control-label">Ключевое слово</label>
                                    <div className="input-group">
                                        <input className="form-control" type="text" name="keyword" size="30" maxLength="128" value={filters.term || ''} onChange={e => this.onChange('term', e)} />
                                    </div>
                                </div>
                                <div className="form-item">
                                    <label className="control-label">Страна</label>
                                    <div className="input-group select-group">
                                        <select className="form-select form-control" name="country" value={currentCountry} onChange={e => this.onChangeClassifier(currentCountry, e)}>
                                            <option value="" defaultValue>- Любое -</option>
                                            { countries.map(item =>
                                                <option value={item.id} key={`country-${item.id}`}>
                                                    { item.name }
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <label className="control-label">Город</label>
                                    <div className="input-group select-group">
                                        <select className="form-select form-control" name="city" value={filters.city} onChange={e => this.onChange('city', e)}>
                                            <option value="" defaultValue>- Любой -</option>
                                            { pathOr([], ['vacancyFilters', 'cities'], data).map((city, index) =>
                                                <option value={path(['city', 'id'], city)} key={`city-${index}`}>
                                                    { path(['city', 'name'], city) }
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <label className="control-label">Функциональное направление</label>
                                    <div className="input-group select-group">
                                        <select className="form-select form-control" name="functional" value={currentFunction} onChange={e => this.onChangeClassifier(currentFunction, e)}>
                                            <option value="" defaultValue>- Любое -</option>
                                            { sortBy(prop('name'), functions).map(item =>
                                                <option value={item.id} key={`function-${item.id}`}>
                                                    { item.name }
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-white" type="submit">
                                <span className="icon" aria-hidden="true" />
                                Поиск
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return <Query query={getVacancyFilters} variables={{
            filter: {
                domain: this.props.domain,
                ...omit(['page', 'city'], this.props.facetFilters),
                cities: path(['city'], this.props.facetFilters) ? [this.props.facetFilters.city] : null
            }
        }}>
            { this.renderFilter }
        </Query>;
    }
};

export default withState('facetFilters', 'setFacetFilters', ({ filters }) => filters)(withRouter(Cover));
