import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, HttpLink } from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import '../assets/styles/index.scss';

import { INTRA } from '../constants/vacancies';
import Vacancies from './Vacancies';
import Header from './Header';
import Footer from './Footer';

const cache = new InMemoryCache();
const link = new HttpLink({
    uri: '/api/graphql'
});

const client = new ApolloClient({
    link,
    cache
});

function App() {
    return (
        <div className={INTRA ? 'page-wrapper' : ''}>
            <Header />
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <Switch>
                        <Route path='/' component={Vacancies} />
                    </Switch>
                </BrowserRouter>
            </ApolloProvider>
            <Footer />
        </div>
    );
}

export default App;
